/* In your CSS file */
.Modal {
  background: white;
  padding: 20px;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  margin: auto;
  margin-top: 7em;
  border-radius: 8px;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
